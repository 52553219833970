import Entity from "./Entity";

class Alert extends Entity {
  constructor() {
    super();
    this.id = null;
    this.actorIds = [];
    this.cves = [];
    this.techniqueIds = null;
    this.subTechniqueIds = null;
    this.tacticIds = null;
    this.sourceType = null;
    this.signatures = [];
    this.type = null;
    this.adversaryTools = [];
    this.name = "";
    this.description = "";
    this.format = null;
    this.supportedPlatforms = [];
    this.executors = [];
    this.recommendation = "";
    this.references = [];
    this.affectedPrograms = [];
    this.version = 1;
    this.shortName = "";
    this.attackPhases = [];
    this.preventionControls = [];

    this.authentication = false;
    this.credentialType = null;
    this.categoryIds = [];
    this.categoryTypeIds = [];
    this.target = "";
  }
}
export default Alert;
