import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SubmenuLayout from "../../../components/Layouts/SubmenuLayout";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import {
  deleteData,
  downloadFile,
  fetchData,
  postData,
  updateData,
  unwrap,
} from "../../../helpers/utils";
import Grid from "@material-ui/core/Grid";
import SectionGroupDescription from "../../../components/SectionGroupDescription";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  AttachFile,
  BugReportOutlined,
  ChevronLeft,
  Save,
} from "@material-ui/icons";
import { HiveIcons } from "../../../assets/icons";
import {
  useDependency,
  useEntityProgress,
  useForm,
  useProgress,
} from "reactcoregk/hooks";
import Attack from "../../../models/Attack";
import {
  clearAttack,
  deleteAttack,
  getAttack,
  updateAttack,
} from "../../../store/attack/actions";
import AttackForm from "../AttackForm";
import { getAllTechniques } from "../../../store/technique/actions";
import DetailsIcon from "@material-ui/icons/Details";
import AttackDetailsSubheader from "./attack-details-subheader";
import UploadDialog from "./UploadDialog";
import FileCard from "../../../components/Common/FileCard";
import { API_URL } from "../../../config";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { ApiEndpoint } from "../../../store/@core/endpoint";
import { EntityType } from "../../../store/@core/entityType";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import BusyIndicator from "../../../components/Common/BusyIndicator";
import Select from "../../../components/Inputs/Select";
import FilePreview from "./FilePreview";
import ManageActorsDialog from "./ManageActorsDialog";
import ManageCvesDialog from "./ManageCvesDialog";
import { createOptions } from "reactcoregk/utils";
import { handleAttackPreSubmit } from "../logic";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  paper: {
    padding: 16,
  },
});

const AttackDetails = (props) => {
  const classes = useStyles();
  const {
    context,
    getAttack,
    getAllTechniques,
    updateAttack,
    clearAttack,
    deleteAttack,
    history,
  } = props;
  const id = props.match.params.id;
  const [form, handleChange, setForm] = useForm(new Attack());
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [busy, error] = useEntityProgress(context.Attack);
  const [openPayload, setOpenPayload] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [openCleanup, setOpenCleanup] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [preview, setPreview] = useState({});
  const [openActors, setOpenActors] = useState(false);
  const [openCves, setOpenCves] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [attackFormCharConstraint, setAttackFormCharConstraint] =
    useState(true);

  const { handleDownloadFile, handleDeleteFile, busyFiles } = useFiles(
    id,
    getAttack
  );

  const { enqueueSnackbar } = useSnackbar();

  const handlePreview = (fileUrl, format) => {
    setPreview({ fileUrl, format });
    setOpenPreview(true);
  };

  useEffect(() => setErrorMessage(error), [error]);
  const attack = context.Attack.get.result;
  const isLoading = context.Attack.get.isLoading;

  useDependency(context.Technique, getAllTechniques);

  const attackDelete = context.Attack.delete;

  useProgress(null, () => history.push("/attacks"), null, attackDelete);

  const [selectedFiles, setSelectedFiles] = useState({
    attackPath: null,
    sigmaRules: null,
  });

  const {
    handleRemoveParameter,
    handleAddParameter,
    parameters,
    loadingParameters,
    handleChangeParam,
    isSaving,
    getParameterOptions,
    getParameterLabel,
  } = useParameters(id, attack?.files);

  useEffect(() => {
    getAttack(id);
    return () => {
      clearAttack();
    };
  }, [getAttack, id, clearAttack]);

  useEffect(() => {
    if (attack.id)
      setForm({
        ...attack,
        actorIds: attack.actorAgents.map((x) => x.actorId),
        attackPhases: attack.attackPhases ?? [],
        preventionControls: attack.preventionControls ?? [],
        categoryTypeIds: attack.categoryTypeIds ?? [],
        categoryIds: attack.categoryIds ?? [],
      });
  }, [attack, setForm]);

  const updateAttackDetails = useCallback(
    async (payload) => {
      try {
        const attackData = await updateData(API_URL + "/api/attacks", payload);
        if (attackData && attackData.message === "OK") {
          enqueueSnackbar("Attack Updated Successfully", {
            variant: "success",
          });
          if (selectedFiles.sigmaRules !== null) {
            const url = `${API_URL}/api/attacks/${id}/upload/sigma-rules`;
            const formData = new FormData();
            formData.append("file", selectedFiles.sigmaRules);
            postData(url, formData);
          }

          if (selectedFiles.attackPath !== null) {
            const url = `${API_URL}/api/attacks/${id}/upload/attack-path`;
            const formData = new FormData();
            formData.append("file", selectedFiles.attackPath);
            postData(url, formData);
          }
        }
      } catch (e) {
        enqueueSnackbar("Operation Failed!", { variant: "error" });
      } finally {
        getAttack(id);
      }
    },
    [selectedFiles]
  );

  const handleSave = (form) => {
    handleAttackPreSubmit(form, setErrorMessage, updateAttackDetails);
  };

  const onActorsSelect = useCallback(
    (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        actorIds: selected,
      }));
    },
    [setForm]
  );

  const onCvesSelect = useCallback(
    (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        cves: selected,
      }));
    },
    [setForm]
  );

  return (
    <SubmenuLayout
      MenuItemsComponent={<AttackDetailsSubheader />}
      title={unwrap(attack, "label") || "Attack"}
    >
      <BusyIndicator busy={isLoading} />
      <Container maxWidth={false}>
        <Box mt={2} />
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item>
            <IconButton component={Link} to={"/attacks"} size={"small"}>
              <ChevronLeft color={"primary"} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography color={"textPrimary"} style={{ fontWeight: "bold" }}>
              Attack Details
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setOpenDelete(true)}
              style={{ color: "tomato" }}
              endIcon={<BugReportOutlined />}
            >
              Delete Attack
            </Button>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Collapse in={!isLoading && !attack}>
          <Alert severity="error" style={{ marginBottom: 16 }}>
            <AlertTitle>Error</AlertTitle>
            Could not found attack with id <strong>{id}</strong>
          </Alert>
        </Collapse>
        <Collapse in={errorMessage || error}>
          <Alert
            severity="error"
            onClose={() => setErrorMessage(null)}
            style={{ marginBottom: 16 }}
          >
            {errorMessage || error}
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12} xs={12}>
            <Paper variant={"outlined"} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item style={{ flex: 1 }}>
                  <SectionGroupDescription
                    title={"Details"}
                    IconComponent={DetailsIcon}
                  />
                </Grid>
                <Grid item>
                  <Button
                    style={{ marginLeft: "auto" }}
                    disabled={busy || !attack || !attackFormCharConstraint}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={() => handleSave(form)}
                  >
                    <Save />
                    Update info
                  </Button>
                </Grid>
              </Grid>

              <Box my={3} />

              <AttackForm
                techs={context.Technique.getAll.result}
                handleChange={handleChange}
                form={form}
                isFullWidth={true}
                setForm={setForm}
                busy={busy || isLoading}
                openActors={() => setOpenActors(true)}
                openCves={() => setOpenCves(true)}
                setAttackFormCharConstraint={setAttackFormCharConstraint}
                setSelectedFiles={setSelectedFiles}
              />
            </Paper>
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            container
            direction={"column"}
            spacing={2}
          >
            <Grid item>
              <Paper variant={"outlined"} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item style={{ flex: 1 }}>
                    <SectionGroupDescription
                      title={"Parameters"}
                      IconComponent={SettingsApplicationsIcon}
                      rightContent={isSaving && "Saving changes.."}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={busy || !attack || loadingParameters}
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => handleAddParameter("file")}
                    >
                      <AttachFile />
                      Insert From Files
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={busy || !attack || loadingParameters}
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => handleAddParameter("text")}
                    >
                      <HiveIcons.AddOutlined />
                      Add Parameter
                    </Button>
                  </Grid>
                </Grid>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Key</TableCell>
                      <TableCell align="right" colSpan={2}>
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parameters.map((param, index) => {
                      const helperText = getHelperText(param, parameters);
                      return (
                        <TableRow key={index}>
                          <TableCell align="right">
                            <TextField
                              margin="none"
                              placeholder={"Enter key"}
                              disabled={
                                loadingParameters ||
                                param.key === "bundle_files"
                              }
                              fullWidth
                              value={param.key}
                              error={Boolean(helperText)}
                              helperText={helperText}
                              onChange={handleChangeParam(index, "key")}
                              label="Key"
                              autoFocus
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Select
                              placeholder={"Select parameter"}
                              label={getParameterLabel(param)}
                              controlId={"parameterValue"}
                              disabled={
                                loadingParameters ||
                                param.key === "bundle_files"
                              }
                              onChange={handleChangeParam(index, "value")}
                              value={param.value}
                              margin={"none"}
                              options={getParameterOptions(param)}
                            />
                          </TableCell>
                          <TableCell style={{ width: 80 }}>
                            <IconButton
                              disabled={
                                loadingParameters ||
                                param.key === "bundle_files"
                              }
                              onClick={() => handleRemoveParameter(index)}
                              style={{ marginRight: 8 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item>
              <Paper variant={"outlined"} className={classes.paper}>
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <SectionGroupDescription
                      title={"Payload"}
                      IconComponent={FolderOpenIcon}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        busy || !attack || isLoading || attack?.payloadPath
                      }
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => setOpenPayload(true)}
                    >
                      <HiveIcons.AddOutlined />
                      Add Payload
                    </Button>
                  </Grid>
                </Grid>
                {attack?.payload && (
                  <Grid container>
                    <Grid item sm={4}>
                      <FileCard
                        file={attack?.payload}
                        busy={busyFiles[attack?.payloadPath]}
                        handlePreview={() =>
                          handlePreview(
                            attack?.payloadPath,
                            attack.payloadFormat
                          )
                        }
                        handleDownload={() =>
                          handleDownloadFile(
                            attack?.payload,
                            attack?.payloadPath,
                            attack?.payloadFormat,
                            "payload"
                          )
                        }
                        handleDelete={() => {
                          handleDeleteFile(attack?.payloadPath, "payload");
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>

            <Grid item>
              <Paper variant={"outlined"} className={classes.paper}>
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <SectionGroupDescription
                      title={"Clean up"}
                      IconComponent={FolderOpenIcon}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        busy || !attack || isLoading || attack?.cleanupCode
                      }
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => setOpenCleanup(true)}
                    >
                      <HiveIcons.AddOutlined />
                      Add cleanup
                    </Button>
                  </Grid>
                </Grid>
                {attack?.cleanupCode && (
                  <Grid container>
                    <Grid item sm={4}>
                      <FileCard
                        file={attack?.cleanupCode}
                        busy={busyFiles[attack?.cleanupCodePath]}
                        handlePreview={() =>
                          handlePreview(
                            attack.cleanupCodePath,
                            attack.cleanupCodeFormat
                          )
                        }
                        handleDelete={() => {
                          handleDeleteFile(
                            attack?.cleanupCodePath,
                            "cleanup-code"
                          );
                        }}
                        handleDownload={() =>
                          handleDownloadFile(
                            attack?.cleanupCode,
                            attack?.cleanupCodePath,
                            attack?.cleanupCodeFormat,
                            "cleanup-code"
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>

            <Grid item>
              <Paper variant={"outlined"} className={classes.paper}>
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <SectionGroupDescription
                      title={"Files"}
                      IconComponent={FolderOpenIcon}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={busy || !attack || isLoading}
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => setOpenFiles(true)}
                    >
                      <HiveIcons.AddOutlined />
                      Add files
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  {attack?.files?.map((file) => (
                    <Grid item sm={4}>
                      <FileCard
                        file={file.name}
                        busy={busyFiles[file.path]}
                        handlePreview={() =>
                          handlePreview(file.path, file.format)
                        }
                        handleDelete={() => {
                          handleDeleteFile(file.path, `file/${file.id}`);
                        }}
                        canDelete={
                          !parameters.find((x) => x.value === file.path)
                        }
                        handleDownload={() =>
                          handleDownloadFile(
                            file.name,
                            file.path,
                            file.format,
                            "file-code"
                          )
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <UploadDialog
        open={openPayload}
        handleClose={() => setOpenPayload(false)}
        entity={attack}
        getAttack={getAttack}
        accept={".yaml,.json"}
        type={"payload"}
      />
      <UploadDialog
        open={openFiles}
        handleClose={() => setOpenFiles(false)}
        entity={attack}
        getAttack={getAttack}
        accept={""}
        hasKey
        type={"file"}
      />
      <UploadDialog
        open={openCleanup}
        handleClose={() => setOpenCleanup(false)}
        entity={attack}
        getAttack={getAttack}
        accept={".yaml,.json"}
        type={"cleanup-code"}
      />
      <FilePreview
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        preview={preview}
      />
      <ManageActorsDialog
        open={openActors}
        attack={form}
        callback={onActorsSelect}
        handleClose={() => setOpenActors(false)}
        refreshAttack={() => getAttack(id)}
      />
      <ManageCvesDialog
        open={openCves}
        attack={form}
        callback={onCvesSelect}
        handleClose={() => setOpenCves(false)}
        refreshAttack={() => getAttack(id)}
      />

      <DeleteConfirmation
        label={"Attack"}
        open={openDelete}
        handleDelete={() => deleteAttack(attack)}
        busy={attackDelete.isLoading}
        errorMessage={attackDelete.error}
        handleClose={() => setOpenDelete(false)}
      />
    </SubmenuLayout>
  );
};

const mapStatetoProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStatetoProps, {
  getAttack,
  getAllTechniques,
  updateAttack,
  clearAttack,
  deleteAttack,
})(AttackDetails);

const parameterOptions = [
  { label: "asset ip address", value: "asset_ip_address" },
  { label: "asset hostname", value: "asset_hostname" },
  { label: "dns server ip address", value: "dns_server_ip_address" },
  { label: "dns server hostname", value: "dns_server_hostname" },
];

function hasDuplicates(arr) {
  return new Set(arr).size !== arr.length;
}

function hasValidParams(parameters) {
  const flatKeys = parameters.map((p) => p.key);
  const flatValues = parameters.map((p) => p.value);

  if (flatKeys.includes("")) {
    return false;
  } else if (flatValues.includes("")) {
    return false;
  } else if (hasDuplicates(flatKeys)) {
    return false;
  }
  return true;
}

function getHelperText(param, parameters) {
  const flatKeys = parameters.map((p) => p.key);
  if (!param.key) {
    return "Empty key not allowed";
  }
  const timesFound = flatKeys.filter((x) => x === param.key).length;
  if (timesFound > 1) {
    return "Duplicated key not allowed";
  }
  return null;
}

const useParameters = (id, files) => {
  const [parameters, setParameters] = useState([]);
  const [busy, setBusy] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const autoSaveTimeout = useRef();
  const initRef = useRef();

  const fileOptions = useMemo(() => {
    return createOptions(files || [], "name", "path");
  }, [files]);

  function getParameterOptions(param) {
    if (param.type === "file" || param.value.includes("/api/files"))
      return fileOptions;
    return parameterOptions;
  }

  function getParameterLabel(param) {
    if (param.type === "file" || param.value.includes("/api/files"))
      return "File";
    return "Parameter value";
  }

  const fetchParams = useCallback(() => {
    setBusy(true);
    fetchData(`${ApiEndpoint[EntityType.Attack]}/${id}/parameters`)
      .then((data) => {
        setParameters(data);
        setBusy(false);
        initRef.current = true;
      })
      .catch((ex) => {
        setBusy(false);
      });
  }, [id]);

  useEffect(() => {
    fetchParams();
  }, [fetchParams]);

  const handleAddParameter = (type) => {
    setParameters((prevState) => [
      ...prevState,
      {
        attackId: id,
        key: "",
        value: type === "text" ? parameterOptions[0].value : null,
        type,
      },
    ]);
  };

  const handleChangeParam = (index, prop) => (e) => {
    const value = e.target ? e.target.value : e.value;
    setParameters((prevState) =>
      prevState.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            [prop]: value,
          };
        }
        return x;
      })
    );
  };

  const handleRemoveParameter = (index) => {
    setParameters((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };

  useEffect(() => {
    if (initRef.current && hasValidParams(parameters)) {
      autoSaveTimeout.current = setTimeout(() => {
        setIsSaving(true);
        postData(
          `${ApiEndpoint[EntityType.Attack]}/${id}/parameters`,
          parameters
        )
          .then(() => setIsSaving(false))
          .catch(() => setIsSaving(false));
      }, 2000);
    }
    return () => {
      clearTimeout(autoSaveTimeout.current);
    };
  }, [id, parameters]);

  return {
    parameters,
    loadingParameters: busy,
    handleAddParameter,
    handleRemoveParameter,
    handleChangeParam,
    isSaving,
    getParameterOptions,
    getParameterLabel,
  };
};

const useFiles = (id, getAttack) => {
  const [busyFiles, setBusyFiles] = useState({});

  const toggleLoader = (path, bool) => {
    setBusyFiles((prevState) => ({
      ...prevState,
      [path]: bool,
    }));
  };

  const handleDeleteFile = async (filePath, type) => {
    toggleLoader(filePath, true);
    const url = `${API_URL}/api/attacks/${id}/upload/${type}`;
    try {
      await deleteData(url);
      getAttack(id);
    } catch (e) {}
    toggleLoader(filePath, false);
  };

  const handleDownloadFile = async (name, filePath, extension) => {
    toggleLoader(filePath, true);
    const url = `${API_URL}${filePath}`;
    const fileName = name.split(".")[0];
    try {
      await downloadFile(url, fileName, extension);
    } catch (e) {}
    toggleLoader(filePath, false);
  };

  return {
    busyFiles,
    handleDeleteFile,
    handleDownloadFile,
  };
};
